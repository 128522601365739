import React, { useState, useEffect } from 'react';

import {
    generateSearchByThemeUrl as originalGenerateSearchByThemeUrl,
    generateSearchByThemeAndCompanyUrl as originalGenerateSearchByThemeAndCompanyUrl,
    generateSearchByCompanyUrl as originalGenerateSearchByCompanyUrl,
    generateSearchFilteredByAds as originalGenerateSearchFilteredByAds,
    generateSearchByExecutiveUrl as originalGenerateSearchByExecutiveUrl,
} from '~/utils/searchUrlUtils';

import { useAuth } from './useAuth';

const useSearchUrls = () => {
    const { user } = useAuth();
    const [contentIndex, setContentIndex] = useState<string>('');
    const [companyIndex, setCompanyIndex] = useState<string>('');

    useEffect(() => {
        if (user) {
            setContentIndex(user.indexes['content']);
            setCompanyIndex(user.indexes['company']);
        }
    }, [user]);

    return {
        generateSearchByThemeUrl: (theme: string) => {
            return originalGenerateSearchByThemeUrl(theme, contentIndex);
        },
        generateSearchByThemeAndCompanyUrl: (theme: string, company: string) => {
            return originalGenerateSearchByThemeAndCompanyUrl(theme, company, contentIndex);
        },
        generateSearchByCompanyUrl: (company: string) => {
            return originalGenerateSearchByCompanyUrl(company, contentIndex);
        },
        generateSearchFilteredByAds: () => {
            return originalGenerateSearchFilteredByAds(contentIndex);
        },
        generateSearchByExecutiveUrl: (executiveName: string) => {
            return originalGenerateSearchByExecutiveUrl(executiveName, contentIndex);
        },
    };
};

export { useSearchUrls };
