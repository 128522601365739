import React, { useState, useEffect, createContext } from 'react';
import ApiClient from '~/utils/apiClient';
import { setSentryUser } from '~/utils/sentry';
import { Meili } from '~/utils/meiliClient';
import type { InstantMeiliSearchInstance } from '@meilisearch/instant-meilisearch';

interface AuthContext {
    isAuthenticated: boolean;
    isAdmin: boolean;
    user?: any;
    refreshUser?: React.Dispatch<React.SetStateAction<boolean>>;
    meili?: Meili;
    meiliClient?: InstantMeiliSearchInstance;
}

const initialAuthState: AuthContext = {
    isAuthenticated: false,
    isAdmin: false,
    user: null,
    refreshUser: undefined,
    meili: undefined,
    meiliClient: undefined,
};

const AuthContext = createContext(initialAuthState);

const AuthProvider = ({ children }: { children: any }): JSX.Element => {
    const isAuthenticated = () => {
        return !!document.getElementById('userLoggedIn');
    };

    const isAdmin = () => {
        return !!document.getElementById('adminLoggedIn');
    };

    const [authState, setAuthState] = useState<AuthContext>(() => {
        return {
            ...initialAuthState,
            isAuthenticated: isAuthenticated(),
            isAdmin: isAdmin(),
        };
    });
    const [refreshUser, setRefreshUser] = useState<boolean>(false);

    const getUser = async () => {
        try {
            const userResponse = await ApiClient.get('/api/account');
            setSentryUser(userResponse.uuid, userResponse.email);

            const initializedMeili = new Meili(
                userResponse.apiKey,
                userResponse.appId,
                userResponse.meiliApiUrl,
                userResponse.meiliApiKey,
                userResponse.indexes,
                userResponse.companyApiKey,
                userResponse.uuid,
            );
            const meiliClient = await initializedMeili.client();
            setAuthState({
                ...authState,
                user: userResponse,
                meiliClient: meiliClient,
                meili: initializedMeili,
            });
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        if (isAuthenticated()) {
            getUser();
        }
    }, []);

    useEffect(() => {
        if (refreshUser && isAuthenticated()) {
            setRefreshUser(false);
            getUser();
        }
    }, [refreshUser]);

    return (
        <AuthContext.Provider value={{ ...authState, refreshUser: setRefreshUser }}>{children}</AuthContext.Provider>
    );
};

export { AuthProvider, AuthContext };
