// Vendor Imports
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import Linkify from 'linkify-react';
import { Carousel } from 'react-responsive-carousel';

// Util Imports
import ApiClient from '~/utils/apiClient';
import Loading from '~/components/loading';
import { useAuth } from '~/hooks/useAuth';

// Layout Imports
import { Container, ContainerWrapper } from '../layout/container';
import Sidebar from '../layout/sidebar';
import ThemePill from './themePill';
import CompanyLink from '../company/components/companyLink';
import ExportContent from './export';

interface CarouselProps {
    hires: string;
    thumbnail: string;
    mime: string;
    width: number;
}

interface ComponentProps {
    contentUuid?: string;
}

const ContentDetails = (props: ComponentProps): JSX.Element => {
    const [content, setContent] = useState<any>();
    let { contentId } = useParams<any>();
    if (props.contentUuid) {
        contentId = props.contentUuid;
    }
    const [currentBackground, setCurrentBackground] = useState<string>();

    const { isAuthenticated, isAdmin } = useAuth();

    const getContent = async () => {
        const contentResponse = await ApiClient.get(`/api/content/${contentId}`);
        setContent(contentResponse);
    };

    useEffect(() => {
        getContent();
    }, []);

    return (
        <ContainerWrapper>
            <Sidebar header="Content Details" expanded>
                {content && !content?.error && (
                    <div className="text-sm w-100 2xl:text-lg grow">
                        {Object.entries(content).map(([key, value]: [any, any]) => {
                            if (!value) return null;

                            if (
                                typeof value === 'string' ||
                                typeof value === 'number' ||
                                Array.isArray(value) ||
                                typeof value === 'object'
                            ) {
                                if (
                                    key.endsWith('_id') ||
                                    key.endsWith('_i') ||
                                    [
                                        'title',
                                        'uuid',
                                        'original_url',
                                        'created_at',
                                        'updated_at',
                                        'assets',
                                        'themes',
                                        'translations',
                                        'description',
                                        'document_page',
                                        'entities',
                                        'sentiment',
                                    ].includes(key)
                                ) {
                                    return null;
                                }
                                if (Array.isArray(value) && !value.length) {
                                    return null;
                                }
                                if (key.endsWith('_at')) {
                                    value = moment(value).format('LL');
                                }
                                return (
                                    <div
                                        className="flex flex-col py-3 border-t border-gray-200 xl:flex-row last:border-b first:border-t-0"
                                        key={key}
                                    >
                                        <div className="w-full xl:w-1/3">
                                            <p className="font-semibold text-black font-primary xl:mr-2">
                                                {key
                                                    .replace('_at', '')
                                                    .split('_')
                                                    .map((w: any) => w[0].toUpperCase() + w.substr(1).toLowerCase())
                                                    .join(' ')}
                                            </p>
                                        </div>
                                        <div className="w-full break-words xl:w-2/3 linkify">
                                            {typeof value === 'object' &&
                                                value !== null &&
                                                value?.name &&
                                                value?.id && (
                                                    <span>
                                                        {key === 'company' ? (
                                                            <CompanyLink id={value.slug || value.id}>
                                                                {value.name}
                                                            </CompanyLink>
                                                        ) : null}
                                                        {key === 'document' ? (
                                                            <a
                                                                href={`/app/company/${value.company_slug}/document/${value.uuid}`}
                                                            >
                                                                {value.display_name}
                                                            </a>
                                                        ) : null}
                                                        {key !== 'company' && key !== 'document' ? (
                                                            <span key={value.id}>{value.name}</span>
                                                        ) : null}
                                                    </span>
                                                )}
                                            {typeof value === 'object' &&
                                                value !== null &&
                                                value?.display_name &&
                                                value?.id && (
                                                    <span>
                                                        {key === 'document' ? (
                                                            <a
                                                                href={`/app/company/${value.company_slug}/document/${value.uuid}`}
                                                            >
                                                                {value.display_name}
                                                            </a>
                                                        ) : null}
                                                    </span>
                                                )}
                                            {Array.isArray(value) && value.length && (
                                                <span>
                                                    {value.map((v: any) => {
                                                        if (typeof v === 'object' && !Array.isArray(v) && v !== null) {
                                                            if (v?.name && v?.id) {
                                                                return (
                                                                    <span key={v.id}>
                                                                        {v.name} <br />
                                                                    </span>
                                                                );
                                                            } else {
                                                                return <></>;
                                                            }
                                                        } else {
                                                            return (
                                                                <span key={v}>
                                                                    {v} <br />
                                                                </span>
                                                            );
                                                        }
                                                    })}
                                                </span>
                                            )}

                                            {!Array.isArray(value) && typeof value !== 'object' ? (
                                                <>
                                                    <Linkify tagName="p" options={{ target: '_blank' }}>
                                                        {value}
                                                    </Linkify>
                                                </>
                                            ) : null}
                                        </div>
                                    </div>
                                );
                            }
                        })}
                        {content?.['themes']?.length ? (
                            <div className="flex flex-col py-3 border-t border-gray-200">
                                <p className="w-full font-semibold text-black font-primary">Themes</p>{' '}
                                <div className="flex flex-row flex-wrap w-full">
                                    {content?.['themes'].map((theme: any) => (
                                        <ThemePill
                                            key={theme.id}
                                            themeName={theme.name}
                                            parentTheme={theme.parent_name}
                                            className="mt-3 mr-3 whitespace-nowrap"
                                        />
                                    ))}
                                </div>
                            </div>
                        ) : null}
                        {isAuthenticated ? (
                            <ExportContent contentId={content?.uuid}>
                                <button className="flex items-center pt-5 pb-3 font-semibold font-primary text-verdant hover:text-verdantDark">
                                    <i className="mr-2 fal fa-file-export" />
                                    Export
                                </button>
                            </ExportContent>
                        ) : null}
                        {content?.['original_url']?.length ? (
                            <a
                                href={content?.['original_url']}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="flex items-center pt-5 pb-3 font-semibold font-primary text-verdant hover:text-verdantDark"
                            >
                                <i className="mr-2 fas fa-external-link fa-fw"></i>
                                Open in New Tab
                            </a>
                        ) : null}

                        {isAdmin ? (
                            <a
                                href={`/admin/contents/${content.uuid}`}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="flex items-center pt-5 pb-3 font-semibold font-primary text-verdant hover:text-verdantDark"
                            >
                                <i className="mr-2 fas fa-user-lock fa-fw"></i>
                                Open in Admin Panel
                            </a>
                        ) : null}
                    </div>
                )}
            </Sidebar>
            <Container noPadding>
                {!content && <Loading isLoading={true} />}
                {content && !content?.error && (
                    <div className="flex flex-col w-full">
                        <div
                            className="transition-all bg-center bg-no-repeat bg-cover"
                            style={{ backgroundImage: `url(${currentBackground})` }}
                        >
                            {content?.['assets']?.length ? (
                                <Carousel
                                    showStatus={false}
                                    showIndicators={false}
                                    className="w-full pt-5 pb-3 -mb-5 backdrop-filter backdrop-blur-lg backdrop-brightness-90"
                                    renderItem={(item: any, options) => {
                                        if (options?.isSelected) {
                                            setCurrentBackground(item.props.thumbnail);
                                        }
                                        if (item?.props?.mime?.startsWith('video')) {
                                            return (
                                                <div className="mx-20 aspect-w-16 aspect-h-9">
                                                    <video
                                                        key={item.props.hires}
                                                        controls
                                                        className="object-cover object-center w-full h-full mx-auto"
                                                        poster={item.props.thumbnail}
                                                    >
                                                        <source src={item.props.hires} type={item.props.mime} />
                                                    </video>
                                                </div>
                                            );
                                        } else if (item?.props?.mime?.startsWith('audio')) {
                                            return (
                                                <div className="w-full h-full mx-auto flex justify-center items-center">
                                                    <audio controls src={item?.props?.hires}>
                                                        Your browser does not support the
                                                        <code>audio</code> element.
                                                    </audio>
                                                </div>
                                            );
                                        } else {
                                            const maxWidth =
                                                item?.props?.width > 500 ? '100%' : `${item?.props?.width}px`;
                                            return (
                                                <img
                                                    alt={'Cometrics Result Image'}
                                                    src={item.props.hires}
                                                    style={{
                                                        maxWidth: maxWidth,
                                                        margin: '0 auto',
                                                        maxHeight: 500,
                                                        objectFit: 'contain',
                                                    }}
                                                />
                                            );
                                        }
                                    }}
                                >
                                    {content?.assets?.map((assetObject: any) => {
                                        if (assetObject?.url && assetObject?.thumbnail) {
                                            const carouselProps: CarouselProps = {
                                                hires: assetObject.url,
                                                thumbnail: assetObject.thumbnail,
                                                mime: assetObject.mime,
                                                width: assetObject.width,
                                            };
                                            return (
                                                <img
                                                    src={carouselProps.thumbnail}
                                                    key={carouselProps.thumbnail}
                                                    {...carouselProps}
                                                />
                                            );
                                        } else {
                                            return null;
                                        }
                                    })}
                                </Carousel>
                            ) : null}
                        </div>
                        <div className="background-offset">
                            <section className="px-5 mt-10 mb-5 linkify">
                                <div className="p-5 bg-white rounded-xl">
                                    <Linkify
                                        tagName="h1"
                                        className="text-base font-semibold break-words font-primary 2xl:text-xl linkify"
                                        options={{ target: '_blank' }}
                                    >
                                        {content?.['title']}
                                    </Linkify>
                                    <Linkify
                                        tagName="p"
                                        className="text-sm break-words font-primary linkify 2xl:text-base"
                                        options={{ target: '_blank' }}
                                    >
                                        {content?.['description']}
                                    </Linkify>
                                </div>
                            </section>
                        </div>
                    </div>
                )}
                {content && content.error && (
                    <div className="container mt-10 text-center">
                        <h1 className="text-2xl font-primary">404 - Content Not Found</h1>
                        <p className="text-lg font-primary">
                            Oops! We aren&apos;t able to find the content you requested.
                        </p>
                    </div>
                )}
                {content?.['uuid']?.length && (
                    <p className="text-center text-vSubtle opacity-30">ID: {content?.['uuid']}</p>
                )}
            </Container>
        </ContainerWrapper>
    );
};

export default ContentDetails;
